define("mgw/components/password-reset-form", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    intl: Ember.inject.service(),
    passwordservice: Ember.inject.service(),
    classNames: ['mgw__content'],
    hasResetPassword: false,
    passwordReset: false,
    emailToReset: Ember.computed.oneWay('currentUser.emailToAutofill'),
    response: null,
    isPerformingRequest: false,
    error: null,
    // request, code, password
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        if (this.isPerformingRequest) {
          return;
        }

        var emailToReset = this.emailToReset;
        this.set('isPerformingRequest', true);
        this.set('error', null);
        this.eventservice.sendEvent('requested-password-reset');
        this.ajax.post(_environment.default.resetPasswordEndpoint + "/request", {
          data: {
            username: emailToReset
          }
        }).then(function (resp) {
          _this.set('isPerformingRequest', false);

          console.info(resp);

          _this.set('response', resp);

          _this.set('hasResetPassword', true);
        }).catch(function () {
          _this.set('isPerformingRequest', false);

          _this.set('error', 'request');
        });
      },
      submitPasswordReset: function submitPasswordReset() {
        var _this2 = this;

        this.set('error', null);

        if (this.get('passwordservice').isPasswordValid(this.newPassword)) {
          this.set('error', null);
        } else {
          this.set('error', 'password');
          return;
        }

        this.ajax.post(_environment.default.resetPasswordEndpoint + "/confirm", {
          data: {
            code: this.code,
            username: this.emailToReset,
            newPassword: this.newPassword
          }
        }).then(function () {
          _this2.set('passwordReset', true);
        }).catch(function (err) {
          //let translationKey = 'genericError';
          if (err.payload.error === "invalidCode") {
            _this2.set('error', 'code');
          } else {
            _this2.set('error', 'password');
          }
        });
      },
      emailDidChange: function emailDidChange() {
        this.set('currentUser.emailCandidateForLogin', this.emailToReset);
      }
    }
  });

  _exports.default = _default;
});