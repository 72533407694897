define("mgw/components/login-form", ["exports", "mgw/config/environment", "ember-local-storage"], function (_exports, _environment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    transitionToIndex: null,
    tagName: "form",
    classNameBindings: [':form', 'hasError:form--invalid'],
    email: Ember.computed.oneWay('autofilledEmail'),
    password: "",
    rememberMe: Ember.computed.alias('settings.rememberMe'),
    hasError: Ember.computed.bool('errorCode'),
    errorCode: null,
    isLoggingIn: false,
    failedToAutoLogin: false,
    onInvalidExistingPassword: null,
    forcePassword: null,
    forceFederationFromProviders: Ember.computed('email', 'ssoproviderservice.ssoProviders.@each.forceLoginForEmailRegex', function () {
      var _this = this;

      if (!this.email.length) {
        return [];
      }

      return this.get('ssoproviderservice.ssoProviders').filter(function (idProvider) {
        return idProvider.forceLoginForEmailRegex && new RegExp(idProvider.forceLoginForEmailRegex).test(_this.email);
      });
    }),
    observeForceFederationFromProviders: Ember.observer('forceFederationFromProviders.[]', function () {
      this.forceFederationFromProvidersDidChange && this.forceFederationFromProvidersDidChange(this.forceFederationFromProviders);
    }),
    failedToAutoLoginAsterisk: Ember.computed('failedToAutoLogin', function () {
      if (this.failedToAutoLogin) {
        return '*';
      } else {
        return '';
      }
    }),
    addADImportDomainSuffix: ['on-premise', 'deploy', 'local'].includes(_environment.default.environment),
    type: Ember.computed('addADImportDomainSuffix', function () {
      if (this.addADImportDomainSuffix) {
        return 'text';
      } else {
        return 'email';
      }
    }),
    submit: function submit() {
      var _this2 = this;

      if (this.isLoggingIn) {
        return;
      }

      var email = this.email;
      var password = this.password;
      var session = this.session;
      this.set('isLoggingIn', true);

      if (this.addADImportDomainSuffix && !email.includes('@')) {
        email = email + '@' + _environment.default.adImportDomain;
      }
      /* eslint-disable-next-line prefer-const */


      var authenticator = this.rememberMe ? 'authenticator:cognito' : 'authenticator:cognito-public';
      /* eslint-disable max-depth */

      session.authenticate(authenticator, {
        username: email,
        password: password
      }).then(function () {
        var loginAction = _this2.onSuccessfulLogin;

        if (loginAction) {
          loginAction();
        }
      }, function (err) {
        _this2.set('isLoggingIn', false);

        if (err.message === "needPasswordReset") {
          _this2.router.transitionTo('invalid-existing-password', {
            queryParams: {
              email: email
            }
          });
        } else if (err.status == 401) {
          if (err.json !== null && err.json.result === "CHALLENGE_REQUESTED") {
            _this2.router.transitionTo('mfa-challenge', {
              queryParams: {
                username: email,
                sessiondata: err.json.session
              }
            });
          } else {
            _this2.set('errorCode', "invalidGrant");
          }
        } else if (err.message == "Network request failed") {
          _this2.set('errorCode', "noNetworkError");
        } else {
          _this2.set('errorCode', "genericError");
        }
      });
      /* eslint-enable max-depth */

      return false;
    },
    actions: {
      readMoreAboutRegisteringAsAUser: function readMoreAboutRegisteringAsAUser() {
        window.open(this.intl.t('readMoreAboutRegisteringAsAUserHereLink'), '_system');
      },
      emailDidChange: function emailDidChange() {
        this.set('currentUser.emailCandidateForLogin', this.email);
      },
      loginUsing: function loginUsing(provider) {
        this.loginUsing(provider, this.email);
      },
      didChangeRememberMe: function didChangeRememberMe(inputElement) {
        this.set('rememberMe', inputElement.target.checked);
      },
      switchRegion: function switchRegion() {
        window.location.href = 'https://global.streambim.com?preventRedirect=true';
      }
    }
  });

  _exports.default = _default;
});