define("mgw/services/passwordservice", ["exports", "mgw/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    clusterConfig: null,
    passwordTitle: Ember.computed('clusterConfig', 'intl.locale', function () {
      var requirements = this.get('clusterConfig.passwordRequirements');
      var text = '';

      if (requirements) {
        var charRequirements = [];

        if (requirements.LowercaseChars) {
          charRequirements.push(this.intl.t('atLeastXLowercaseLetters', {
            count: requirements.LowercaseChars
          }));
        }

        if (requirements.UppercaseChars) {
          charRequirements.push(this.intl.t('atLeastXUppercaseLetters', {
            count: requirements.UppercaseChars
          }));
        }

        if (requirements.Digits) {
          charRequirements.push(this.intl.t('atLeastXDigits', {
            count: requirements.Digits
          }));
        }

        if (requirements.Symbols) {
          charRequirements.push(this.intl.t('atLeastXSymbols', {
            count: requirements.Symbols
          }));
        }

        if (requirements.PasswordLength) {
          text += this.intl.t('passwordNeedsToBeAtLeastXCharactersLong', {
            length: requirements.PasswordLength
          }) + ' ';
        }
        /* eslint-disable max-depth */


        if (charRequirements.length) {
          text += this.intl.t('with') + ' ';

          if (charRequirements.length > 1) {
            charRequirements[charRequirements.length - 1] = this.intl.t('and').toLowerCase() + ' ' + charRequirements[charRequirements.length - 1];
          }

          text += charRequirements.join(', ');
          text += '.';
        }
        /* eslint-enable max-depth */

      }

      return text || this.intl.t(_environment.default.passwordTitle);
    }),
    isPasswordValid: function isPasswordValid(password) {
      var requirements = this.get('clusterConfig.passwordRequirements');

      if (!requirements) {
        return true;
      }

      if (requirements.PasswordLength && password.length < requirements.PasswordLength) {
        return false;
      }

      if (requirements.LowercaseChars && !/[a-z]/.test(password)) {
        return false;
      }

      if (requirements.UppercaseChars && !/[A-Z]/.test(password)) {
        return false;
      }

      if (requirements.Digits && !/\d/.test(password)) {
        return false;
      }

      if (requirements.Symbols && !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
        return false;
      }

      return true;
    },
    load: function load() {
      var _this = this;

      if (this.get('clusterConfig')) {
        return Ember.RSVP.resolve();
      }

      return this.store.findAll('clusterConfiguration').then(function (res) {
        if (res && res.firstObject) {
          _this.set('clusterConfig', res.firstObject);
        } else {
          _this.set('clusterConfig', {});
        }
      });
    }
  });

  _exports.default = _default;
});